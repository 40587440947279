import * as React from "react";
import Layout from "../components/layout";

const ServicesPage = () => {
  return (
    <Layout>
      <section class="section">
        <h1 className="title is-size-3">Career Coaching</h1>
        <h2 className="subtitle">
          Are you wondering how to kick start your career? Or how to take your
          career to the next level? Career coaching could be the option for you.
        </h2>
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">Initial consultation</h1>
            <div className="columns">
              <div className="column">
                This will be a telephone appointment which can last up to 15
                minutes. <br />
                You will speak with Steve about what you hope to gain from
                career coaching.
              </div>
              <div className="column is-2 is-size-5 is-vcentered">Free</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">
              Individual Session – 30 minutes{" "}
            </h1>
            <div className="columns">
              <div className="column">
                Each session will last for 30 minutes and will be conducted
                either face to face or on Microsoft Teams.
              </div>
              <div className="column is-2 is-size-5 is-centred">£40</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-content">
            <h1 className="subtitlqe is-size-5">
              Individual Session – 60 minutes{" "}
            </h1>
            <div className="columns">
              <div className="column">
                Each session will last for 60 minutes and will be conducted
                either face to face or on Microsoft Teams.
              </div>
              <div className="column is-2 is-size-5 is-centred">£75</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">Block Sessions</h1>
            <div className="columns">
              <div className="column">
                Each session will last for{" "}
                <span className="has-text-weight-bold">60 minutes</span> and
                will be conducted either face to face or on Microsoft Teams.
                <span className="has-text-weight-bold">
                  <br />
                  *Block sessions must be booked in the appropriate time frame.
                </span>
              </div>
            </div>
            <div className="level"></div>
            <div className="columns">
              <div className="column subtitle is-size-5 has-text-info">
                4 Sessions
              </div>
              <div className="column is-2 is-size-5 is-centred">£275</div>
            </div>
            <div className="level"></div>
            <div className="columns">
              <div className="column subtitle is-size-5 has-text-info">
                8 Sessions
              </div>
              <div className="column is-2 is-size-5 is-centred">£550</div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <h1 className="title is-size-3">Personal Development Coaching </h1>
        <h2 className="subtitle">
          Are you up for promotion? Or looking for Professional development
          guidance? Personal development coaching could be for you.
        </h2>
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">Initial consultation</h1>
            <div className="columns">
              <div className="column">
                This will be a telephone appointment which can last up to 15
                minutes. <br />
                You will speak with Steve about what you hope to gain from
                career coaching.
              </div>
              <div className="column is-2 is-size-5 is-centred">Free</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">
              Individual Session – 30 minutes{" "}
            </h1>
            <div className="columns">
              <div className="column">
                Each session will last for 30 minutes and will be conducted
                either face to face or on Microsoft Teams.
              </div>
              <div className="column is-2 is-size-5 is-centred">£40</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-content">
            <h1 className="subtitle is-size-5">
              Individual Session – 60 minutes{" "}
            </h1>
            <div className="columns">
              <div className="column">
                Each session will last for 60 minutes and will be conducted
                either face to face or on Microsoft Teams.
              </div>
              <div className="column is-2 is-size-5 is-centred">£75</div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPage;
